export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient()

  if (!user.value) {
    return navigateTo('/')
  }

  const { data: userData, error } = await supabase.rpc('get_my_claims')
  
  if (error || userData.app_role !== 'super_admin') {
    return navigateTo('/')
  }
})
